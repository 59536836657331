import React, { memo as Memo, useRef, useEffect, useCallback, useMemo } from 'react'
import { gsap } from 'gsap'

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer'
import { withRouter } from 'next/router'

//* Components
import Text from '../Text'
import breakString from '@helpers/breakString'
import Ziggy from '../SvgComponents/Ziggy'
import Sharpy from '../SvgComponents/Sharpy'
import Rolly from '../SvgComponents/Rolly'
import Peezzy from '../SvgComponents/Peezzy'

//* Style 
import style from './style.module.scss'

const PageTopSection = Memo(({ className, innerTitle, ...props }) => {
    const AnimationComponent = useMemo(() => {
        switch (props.serviceType) {
            case 'Branding':
                return Peezzy
            case 'E-commerce':
                return Ziggy
            case 'Digital':
                return Sharpy
            case 'Product':
                return Rolly
            default:
                return Peezzy
        }
    }, [props.serviceType])

    //! Refs
    const topRef = useRef()
    const topRef1 = useRef()
    const topRef2 = useRef()
    const topRef3 = useRef()
    const topRef5 = useRef()

    //! Animation
    const tl = useMemo(() => gsap.timeline({ delay: 0.6 }), [])

    useEffect(() => {
        let arr = [topRef1.current?.children, topRef5.current?.children[0], topRef5.current?.children[1].children, topRef2.current, topRef3.current]
        let arr1 = [topRef1.current?.children[1], topRef5.current?.children[1].children[1]]

        arr = arr.filter((el) => { return el != null })
        arr1 = arr1.filter((el) => { return el != null })

        tl.staggerTo(
            [arr], 0, {
            opacity: 1
        }, 0.1)
            .set(
                [arr1], {
                delay: 0.3,
                css: { fontStyle: 'italic' }
            }, 0.1);
    }, [])

    const sectionAnimation = useCallback(() => {
        if (topRef.current) {

            const elTop = topRef.current.getBoundingClientRect().top

            if (elTop < props.winHeight) {
                tl.play();
            }
            else if (elTop >= props.winHeight) {
                tl.reverse();
            }
        }
    }, [topRef])


    useEffect(() => {
        window.addEventListener('scroll', sectionAnimation)

        return () => {
            window.removeEventListener('scroll', sectionAnimation)
        }
    }, [sectionAnimation])

    return (
        <div className={`${style.ContainerWrap} ${props.settings?.min_title ? '' : style.contMarg}  ${className || ''} ${props.settings?.underline ? style.underline : ' '} `} ref={topRef}>
            {
                props.title && !innerTitle &&
                <Text ref={topRef1} tag={`${props.titleTag || (props.settings?.min_title ? "h2" : "h1")}`} className={`${props.settings?.min_title ? "h1" : "h1L"} font-poppins font-semi-bold ${style.title}`}>
                    {breakString(props.title)}
                </Text>

            }
            {
                innerTitle &&
                <div className={style.servicesTop}  >
                    <div className={style.servicesAnimationWrap}>
                        <AnimationComponent />
                    </div>
                    <div className={style.servicesTitle} ref={topRef5}>
                        <Text className={`h2 font-anonymous font-bold ${style.servicesCounter}`} children={"0" + innerTitle} />

                        <Text tag={"h1"} className={`h1L font-poppins font-semi-bold ${style.titleOther}`} children={breakString(props.title)} />
                    </div>
                </div>
            }
            {
                props.subtitle &&
                <Text tag={props.subtitleTag || 'h2'} ref={topRef2} className={`${props.settings?.text_size_small ? "h4" : "h3"} font-poppins font-light ${style.subtitle}`} text={props.subtitle} />

            }
            {
                props.description &&
                <div className={style.position_right}>
                    <Text ref={topRef3} className={`${props.settings?.text_size_small ? "p-l" : "p-m"} ${props.settings?.short_size ? style.short : style.long} font-poppins font-light ${style.description}`} text={props.description} />
                </div>
            }
        </div>
    )
})

export default withRouter(withUIContext(PageTopSection, ['winHeight']))