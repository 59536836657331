import React, { memo as Memo, useMemo, useEffect, Fragment, useCallback } from 'react'
import Lottie from 'react-lottie'
import ziggyJSON from './ziggyJSON.json'

//* Style's
import style from './style.module.scss'

const Ziggy = Memo(props => { // @refresh reset
    const defaultOptions = useMemo(() => ({
        loop: true,
        autoplay: true,
        animationData: ziggyJSON,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }), [])

    return (
        <div className={style.ziggy}>
            <Lottie
                options={defaultOptions}
                height={'auto'}
                width={'100%'}
                isStopped={!props.play}
                isPaused={false}
            />
        </div>
    )
})

export default Ziggy